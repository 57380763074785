import React from "react";
import ReactDOM from "react-dom";
import {
  ApolloProvider,
  ApolloClient,
  from,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

import environment from "./environment";
import { LogsProvider } from "context/logs/logs.provider";
import { AuthProvider } from "./context/auth/auth.provider";
import { HostProvider } from "./context/host/host.provider";
import { AgenciaProvider } from "./context/agencia/agencia.provider";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import mgTheme from "./assets/mgTheme";

import App from "./components/app/App";

import { unregister } from "./registerServiceWorker";
import asyncWithLDProvider from "launchdarkly-react-client-sdk/lib/asyncWithLDProvider";
import LDUserIdentifier from "components/app/featureFlags/LDUserIdentifier";

// Delete me!
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("accessToken");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
  link: from([
    authLink,
    errorLink,
    new HttpLink({
      uri: process.env.REACT_APP_API_MARKETPLACE_URL + "/graphql",
      credentials: "same-origin",
    }),
  ]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          misPublicaciones: {
            keyArgs: ["estado"],
            merge(existing, incoming, { args: { offset = 0 } }) {
              if (offset === 0) return incoming;

              const merged = existing ? existing.items.slice(0) : [];
              for (let i = 0; i < incoming.items.length; ++i) {
                merged[offset + i] = incoming.items[i];
              }

              return {
                hasMore: incoming.hasMore,
                items: merged,
                totalCount: incoming.totalCount,
                __typename: incoming.__typename,
              };
            },
          },
        },
      },
    },
  }),
});

declare global {
  interface Window {
    render_front_autos: Function;
    unmount_front_autos: Function;
  }
}

const ExtendedApp = async (host, history) => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_SDK_KEY,
    options: { streaming: process.env.REACT_APP_ENV === "test" ? false : true },
  });

  return (
    <React.StrictMode>
      <LogsProvider>
        <ApolloProvider client={client as any}>
          <AuthProvider
            domain={environment.auth0.domain ?? ""}
            clientId={environment.auth0.clientId ?? ""}
          >
            <HostProvider host={host}>
              <AgenciaProvider>
                <LDProvider>
                  <LDUserIdentifier />
                  <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={mgTheme}>
                      <CssBaseline />
                      <App history={history} />
                    </ThemeProvider>
                  </StyledEngineProvider>
                </LDProvider>
              </AgenciaProvider>
            </HostProvider>
          </AuthProvider>
        </ApolloProvider>
      </LogsProvider>
    </React.StrictMode>
  );
};

window.render_front_autos = async (containerId, host, history) => {
  ReactDOM.render(
    await ExtendedApp(host, history),
    document.getElementById(containerId)
  );
  unregister();
};

window.unmount_front_autos = (containerId) => {
  ReactDOM.unmountComponentAtNode(document.getElementById(containerId));
};
